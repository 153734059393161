import React, { Component, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import './load.scss';

moment.locale('pt-br');

const Router = React.lazy(() => import('./Router'));

class App extends Component<any, any> {
  render() {
    return (
      <BrowserRouter basename="/">
        <Suspense fallback={<div className='center'><div className="loader"></div><div className='break'>Carregando...</div></div>}>
          <Router />
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
